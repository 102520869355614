// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import {BsPatchCheckFill} from "react-icons/bs"
// export default function PaymentCard({
//   toCheckoutOrder,
//   cardDetails,
//   setCardDetails,
// }) {
//   const [code,setCode]=useState(generateOTP())
//   const [inputCode,setInputCode]=useState('');
//   const [codeVerified,setCodeVerified]=useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setCardDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const pay = (e) => {
//     e.preventDefault();
//     toCheckoutOrder();
//   };

//   function generateOTP() {
//     const digits = "0123456789";
//     let otp = "";

//     for (let i = 0; i < 4; i++) {
//       const randomIndex = Math.floor(Math.random() * digits?.length);
//       otp += digits[randomIndex];
//     }
//     return otp;
//   }

//  useEffect(()=>{
// if(inputCode==code && inputCode?.length==4){
//   setCodeVerified(true)
// }else if(inputCode!=code && inputCode?.length==4){
//   setCode(generateOTP())
//   setInputCode('')
//   setCodeVerified(false)
// }else{
//   setCodeVerified(false)
// }
//  },[inputCode])

//   return (
//     <>
//       <form action="#" autoComplete="off">
//         <div className="form-row">
//           <div className="col-md-6">
//             <div className="form-group">
//               <label htmlFor="cardFirstName" className="font-weight-600">
//                 Card Holder First name
//               </label>
//               <input
//                 type="text"
//                 name="cardFirstName"
//                 id="cardFirstName"
//                 value={cardDetails?.cardFirstName}
//                 onChange={(e) => handleInputChange(e)}
//                 className="form-control"
//                 placeholder="Card Holder First name"
//                 required=""
//               />
//             </div>
//           </div>

//           <div className="col-md-6">
//             <div className="form-group">
//               <label htmlFor="cardLastName" className="font-weight-600">
//                 Card Holder Last name
//               </label>
//               <input
//                 type="text"
//                 name="cardLastName"
//                 id="cardLastName"
//                 value={cardDetails?.cardLastName}
//                 onChange={(e) => handleInputChange(e)}
//                 className="form-control"
//                 placeholder="Card Holder Last name"
//                 required=""
//               />
//             </div>
//           </div>
//         </div>

//         <div className="col-md-12 p-0">
//           <div className="form-group">
//             <label htmlFor="cardNumber" className="font-weight-600">
//               Credit Card Number
//             </label>
//             <input
//               type="text"
//               name="cardNumber"
//               id="cardNumber"
//               value={cardDetails?.cardNumber}
//               onChange={(e) => handleInputChange(e)}
//               className="form-control"
//               placeholder="Enter Credit Card Number"
//               required=""
//             />
//           </div>
//         </div>

//         <div className="form-row">
//           <div className="col-md-6">
//             <div className="form-group">
//               <label htmlFor="expiryMonth" className="font-weight-600">
//                 Expiry Date
//               </label>
//               <select
//                 name="expiryMonth"
//                 id="expiryMonth"
//                 value={cardDetails?.expiryMonth}
//                 onChange={(e) => handleInputChange(e)}
//                 required=""
//                 className="form-control"
//               >
//                 <option value="">Month</option>
//                 <option value="01">January</option>
//                 <option value="02">February</option>
//                 <option value="03">March</option>
//                 <option value="04">April</option>
//                 <option value="05">May</option>
//                 <option value="06">June</option>
//                 <option value="07">July</option>
//                 <option value="08">August</option>
//                 <option value="09">September</option>
//                 <option value="10">October</option>
//                 <option value="11">November</option>
//                 <option value="12">December</option>
//               </select>
//             </div>
//           </div>

//           <div className="col-md-6">
//             <div className="form-group">
//               <label htmlFor="expiryYear" className="sr-sm-only">&nbsp;</label>
//               <select
//                 name="expiryYear"
//                 id="expiryYear"
//                 value={cardDetails?.expiryYear}
//                 onChange={(e) => handleInputChange(e)}
//                 className="form-control pull-right"
//               >
//                 <option value="">Year</option>
//                 <option value="2023">2023</option>
//                 <option value="2024">2024</option>
//                 <option value="2025">2025</option>
//                 <option value="2026">2026</option>
//                 <option value="2027">2027</option>
//                 <option value="2028">2028</option>
//                 <option value="2029">2029</option>
//                 <option value="2030">2030</option>
//                 <option value="2031">2031</option>
//                 <option value="2032">2032</option>
//                 <option value="2033">2033</option>
//                 <option value="2034">2034</option>
//                 <option value="2035">2035</option>
//                 <option value="2036">2036</option>
//                 <option value="2037">2037</option>
//               </select>
//             </div>
//           </div>
//         </div>

//         <div className="form-row">
//           <div className="col-md-12">
//             <div className="form-group">
//               <label htmlFor="cvv" className="font-weight-600">
//                 cvv
//               </label>
//               <input
//                 type="password"
//                 name="cvv"
//                 id="cvv"
//                 value={cardDetails?.cvv}
//                 onChange={(e) => handleInputChange(e)}
//                 className="form-control"
//                 placeholder="Enter CCV"
//                 required=""
//                 fdprocessedid="0pz11d"
//               />
//             </div>
//           </div>

//           <div className="col-md-12">
//             <label htmlFor="scode" className="font-weight-600">
//               Security Code
//             </label>
//             <div className="input-group mb-3">
//               <input
//                 type="text"
//                 id="scode"
//                 className="form-control border-right-0"
//                 placeholder="Enter Code →"
//                 value={inputCode}
//                 onChange={(e)=>setInputCode(e.target.value)}
//               />

//               <div className="input-group-append">
//                 <span className="input-group-text border-left-0 border-custom fa-18x font-weight-600">
//                   {code}
//                 </span>
//               </div>
//              {codeVerified && <div style={{position:"absolute",display:"flex",alignItems:"center",right:"100px",top:"15px"}}><BsPatchCheckFill style={{color:"green",fontSize:"24px"}} /></div>}
//             </div>

//           </div>
//         </div>

//         <div className="custom-control custom-checkbox mb-3">
//           <input
//             type="checkbox"
//             className="custom-control-input"
//             id="customCheck"
//           />
//           <label
//             className="custom-control-label fa-18x fa-sm-14 text-theme fw-500"
//             htmlFor="customCheck"
//           >
//                &nbsp; By proceeding with this purchase, I acknowledge that I have read and
//                agree to the
//               <Link to="#" className="ancher-text">
//                 <b> terms and conditions </b>
//               </Link>
//             of use
//           </label>
//         </div>

//         <div className="col-md-12">
//           <div className="mt-4">
//             <input
//               type="submit"
//               name="submit"
//               className="btn btn-theme"
//               onClick={pay}
//               value="Pay Now"
//               // disabled={
//               //   !cardFirstName ||
//               //   !cardLastName ||
//               //   !cardNumber ||
//               //   !expiryMonth ||
//               //   !expiryYear ||
//               //   !cvv
//               // }
//             />
//           </div>
//         </div>
//       </form>
//     </>
//   );
// }
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsPatchCheckFill } from "react-icons/bs";
import {toast} from "react-toastify"
import "./Payment.css";
export default function PaymentCard({
  toCheckoutOrder,
  cardDetails,
  setCardDetails,
  loader
}) {
  const [code, setCode] = useState(generateOTP());
  const [inputCode, setInputCode] = useState("");
  const [codeVerified, setCodeVerified] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [termsChecked, setTermsChecked] = useState(false)


  // const [errors, setErrors] = useState({
  //   cardFirstName: false,
  //   cardLastName: false,
  //   cardNumber: false,
  //   expiryMonth: false,
  //   expiryYear: false,
  //   cvv: false,
  // });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "cardNumber") {
  //     if (/^\d*$/.test(value) && value?.length <= 16) {
  //       setCardDetails((prevDetails) => ({
  //         ...prevDetails,
  //         [name]: value,
  //       }));
  //       setErrorMessage("");
  //     } else {
  //       setErrorMessage("Please enter up to 16 numbers only.");
  //       setCardDetails((prevDetails) => ({
  //         ...prevDetails,
  //         [name]: value.substring(0, 16),
  //       }));
  //     }
  //   }
  //   setCardDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };

  const pay = (e) => {
    e.preventDefault();
    // if (validateFields() && codeVerified)
    if (codeVerified) {
      toCheckoutOrder();
    }else{
      setCode(generateOTP());
      toast.error('Security code does not match!')
    }
  };

  // const validateFields = () => {
  //   const fieldErrors = {
  //     cardFirstName: !cardDetails.cardFirstName,
  //     cardLastName: !cardDetails.cardLastName,
  //     cardNumber: !cardDetails.cardNumber,
  //     expiryMonth: !cardDetails.expiryMonth,
  //     expiryYear: !cardDetails.expiryYear,
  //     cvv: !cardDetails.cvv,
  //   };

  //   setErrors(fieldErrors);

  //   return Object.values(fieldErrors).every((error) => !error);
  // };

  function generateOTP() {
    const digits = "0123456789";
    let otp = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * digits?.length);
      otp += digits[randomIndex];
    }
    return otp;
  }

  useEffect(() => {
    if (inputCode === code && inputCode?.length === 4) {
      setCodeVerified(true);
    } else if (inputCode !== code && inputCode?.length > 4) {
      setCode(generateOTP());
      setInputCode("");
      setCodeVerified(false);
    } else {
      setCodeVerified(false);
    }
  }, [inputCode]);

  return (
    <>
      <form action="#"     onSubmit={pay}>
        {/* <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="cardFirstName" className="font-weight-600">
                Card Holder First name
              </label>
              <input
                type="text"
                name="cardFirstName"
                id="cardFirstName"
                value={cardDetails?.cardFirstName}
                onChange={(e) => handleInputChange(e)}
                className="form-control"
                placeholder="Card Holder First name"
                required=""
              />
              {errors.cardFirstName && (
                <p className="error-message">
                  Card Holder First name is required
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="cardLastName" className="font-weight-600">
                Card Holder Last name
              </label>
              <input
                type="text"
                name="cardLastName"
                id="cardLastName"
                value={cardDetails?.cardLastName}
                onChange={(e) => handleInputChange(e)}
                className="form-control"
                placeholder="Card Holder Last name"
                required=""
              />
              {errors.cardLastName && (
                <p className="error-message">
                  Card Holder Last name is required
                </p>
              )}
            </div>
          </div>
        </div> */}
        {/* <div className="col-md-12 p-0">
          <div className="form-group">
            <label htmlFor="cardNumber" className="font-weight-600">
              Credit Card Number
            </label>
            <input
              type="number"
              name="cardNumber"
              id="cardNumber"
              inputMode="numeric"
              value={cardDetails?.cardNumber}
              onChange={(e) => handleInputChange(e)}
              className="form-control"
              placeholder="Enter Credit Card Number"
              required=""
            />
            <div style={{ color: "red" }}>{errorMessage}</div>
            {errors.cardNumber && (
              <p className="error-message">Credit Card Number is required</p>
            )}
          </div>
        </div> */}
        {/* <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="expiryMonth" className="font-weight-600">
                Expiry Date
              </label>
              <select
                name="expiryMonth"
                id="expiryMonth"
                value={cardDetails?.expiryMonth}
                onChange={(e) => handleInputChange(e)}
                required=""
                className="form-control"
              >
                <option value="">Month</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">Mar</option>
                <option value="4">Apr</option>
                <option value="5">May</option>
                <option value="6">Jun</option>
                <option value="7">Jul</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </select>
              {errors.expiryMonth && (
                <p className="error-message">Expiry Month is required</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="expiryYear" className="sr-sm-only">
                &nbsp;
              </label>
              <select
                name="expiryYear"
                id="expiryYear"
                value={cardDetails?.expiryYear}
                onChange={(e) => handleInputChange(e)}
                className="form-control pull-right"
              >
                <option value="">Year</option>
                <option value="23">2023</option>
                <option value="24">2024</option>
                <option value="25">2025</option>
                <option value="26">2026</option>
                <option value="27">2027</option>
                <option value="28">2028</option>
                <option value="29">2029</option>
                <option value="30">2030</option>
                <option value="31">2031</option>
                <option value="32">2032</option>
                <option value="33">2033</option>
                <option value="34">2034</option>
                <option value="35">2035</option>
                <option value="36">2036</option>
                <option value="37">2037</option>
                <option value="38">2038</option>
              </select>
              {errors.expiryYear && (
                <p className="error-message">Expiry Year is required</p>
              )}
            </div>
          </div>
        </div> */}
        <div className="form-row">
          {/* <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="cvv" className="font-weight-600">
                CVV
              </label>
              <input
                type="password"
                name="cvv"
                id="cvv"
                value={cardDetails?.cvv}
                onChange={(e) => handleInputChange(e)}
                className="form-control"
                placeholder="Enter CVV"
                required=""
              />
              {errors.cvv && <p className="error-message">CVV is required</p>}
            </div>
          </div> */}
          <div className="col-md-12">
            <label htmlFor="scode" className="font-weight-600">
              Security Code <span className="text-danger">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                id="scode"
                required
                className="form-control border-right-0"
                placeholder="Enter Code →"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text border-left-0 border-custom fa-18x font-weight-600">
                  {code}
                </span>
              </div>
              {codeVerified && (
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    right: "100px",
                    top: "15px",
                  }}
                >
                  <BsPatchCheckFill
                    style={{ color: "green", fontSize: "24px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="custom-control custom-checkbox mb-3">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck"
            required
            value={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
          />
          <label
            className="custom-control-label fa-18x fa-sm-14 text-theme fw-500 pt-1"
            htmlFor="customCheck"
          >
            &nbsp; By Proceeding With This Enquiry, I acknowledge that I have
            read and agree to the
            <Link to="#" className="ancher-text">
              <b> terms and conditions </b>
            </Link>
            of use
          </label>
        </div>
        <div className="col-md-12 pl-0">
          <div className="mt-4">
            <button
              type="submit"
              name="submit"
              className="btn btn-theme"
              disabled={loader}
            >{loader ? "Processing" : "Submit"}</button>
          </div>
        </div>
      </form>
    </>
  );
}
